<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>

        <div class="vx-card__title mb-6">
          <h2 class="text-center">Welcome</h2>
        </div>

        <img src="@/assets/images/pages/confirm_ok.png" alt="coming-soon" class="mx-auto mb-2" width="150" v-if="isConfirmed == 1 || isConfirmed == 2">
        <img src="@/assets/images/pages/confirm_error.png" alt="coming-soon" class="mx-auto mb-2" width="150" v-else>

        <div class="count-down text-center">
          <span v-if="isConfirmed==1">Confirmed.</span>
          <span v-else-if="isConfirmed==2">Already Confirmed.</span>
          <span v-else-if="isConfirmed==3">Contacte Soporte</span>
        </div>

        <vs-divider position="center"></vs-divider>

        <div class="subscription">

          <vs-button class="w-full" @click="goToLogin()">Go to Login</vs-button>

        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>

  import {userService} from '../../services/user.service'
export default {
  data() {
    return {
      isConfirmed: 0,
    }
  },
  components: {

  },
  created () {
    this.confirm();
  },
  methods: {
    confirm(){
      let ctok = this.$route.params.token;
      userService.confirm(ctok)
              .then(
                      response => {
                        if (response && response.code == 'already_confirmed') {
                          this.isConfirmed = 2;
                        }else if (response && response.code == 'confirmed') {
                          this.isConfirmed = 1;
                        } else {
                          this.isConfirmed = 3;
                        }
                      },
                      error => {
                        if(!error) return;
                        this.isConfirmed = 3;
                      }
              )
    },
    goToLogin(){
      this.$router.push('/pages/login').catch(() => {})
    }
  }
}

</script>

